import type {
	BackofficeApi,
	BackofficeApiGetBankAccountTransactionListRequest,
	BackofficeApiGetBankAccountTransactionRequest,
	BackofficeApiGetBankAccountTransactionTagListRequest,
	BackofficeApiGetBankAccountTransactionTagRequest,
	BackofficeApiGetBankAccountTransactionVendorListRequest,
	BackofficeApiGetBankAccountTransactionVendorRequest,
	BackofficeApiGetUploadedFilesFromGoogleDriveRequest,
} from '@features/api/client';
import constants from '@features/backoffice/shared/domain/constants';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const INTERNAL__bankTransactionsQueryConfig = (apiClient: BackofficeApi) =>
	createQueryKeys('accounting', {
		balances: {
			queryKey: ['balances'],
			contextQueries: {
				single: () => ({
					queryKey: ['single', constants.BANK_ACCOUNT_DATA_ID],
					queryFn: () => {
						return apiClient
							.getBankAccountData({ id: constants.BANK_ACCOUNT_DATA_ID })
							.then((res) => res.data);
					},
				}),
			},
		},
		transactions: {
			queryKey: ['transactions'],
			contextQueries: {
				list: (body: BackofficeApiGetBankAccountTransactionListRequest) => ({
					queryKey: ['list', ...Object.values(body)],
					queryFn: () => {
						return apiClient.getBankAccountTransactionList(body).then((res) => res.data);
					},
				}),
				single: (body: BackofficeApiGetBankAccountTransactionRequest) => ({
					queryKey: ['single', ...Object.values(body)],
					queryFn: () => {
						return apiClient.getBankAccountTransaction(body).then((res) => res.data);
					},
				}),
				uploadedFiles: (body: BackofficeApiGetUploadedFilesFromGoogleDriveRequest) => ({
					queryKey: ['uploaded-files'],
					queryFn: () => {
						return apiClient.getUploadedFilesFromGoogleDrive(body).then((res) => res.data);
					},
				}),
				tags: {
					queryKey: ['tags'],
					contextQueries: {
						list: (body: BackofficeApiGetBankAccountTransactionTagListRequest) => ({
							queryKey: ['list', ...Object.values(body)],
							queryFn: () => {
								return apiClient
									.getBankAccountTransactionTagList(body)
									.then((res) => res.data);
							},
						}),
						single: (body: BackofficeApiGetBankAccountTransactionTagRequest) => ({
							queryKey: ['single', ...Object.values(body)],
							queryFn: () => {
								return apiClient.getBankAccountTransactionTag(body).then((res) => res.data);
							},
						}),
					},
				},
				vendors: {
					queryKey: ['vendors'],
					contextQueries: {
						list: (body: BackofficeApiGetBankAccountTransactionVendorListRequest) => ({
							queryKey: ['list', ...Object.values(body)],
							queryFn: () => {
								return apiClient
									.getBankAccountTransactionVendorList(body)
									.then((res) => res.data);
							},
						}),
						single: (body: BackofficeApiGetBankAccountTransactionVendorRequest) => ({
							queryKey: ['single', ...Object.values(body)],
							queryFn: () => {
								return apiClient
									.getBankAccountTransactionVendor(body)
									.then((res) => res.data);
							},
						}),
					},
				},
			},
		},
	});
