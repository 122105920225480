import useApiClient from '@features/api/useApiClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { BankAccountTransactionVendorRequest } from '@features/api/client';

function INTERNAL__useCreateBankTransactionVendor({ transactionId }: { transactionId?: number }) {
	const { queries, backofficeApiClient } = useApiClient();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (body: BankAccountTransactionVendorRequest) => {
			return backofficeApiClient
				.createBankAccountTransactionVendor({
					bankAccountTransactionVendorRequest: body,
				})
				.then((res) => res.data);
		},
		onSettled: () => {
			if (!transactionId) return;

			queryClient.invalidateQueries({
				queryKey: queries.bankTransactions.transactions._ctx.single({ id: transactionId }).queryKey,
			});
		},
	});
}

export default INTERNAL__useCreateBankTransactionVendor;
