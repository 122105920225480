import useApiClient from '@features/api/useApiClient';
import { useMutation } from '@tanstack/react-query';
import { $path } from 'next-typesafe-url';
import { useRouter } from 'next/navigation';

export function INTERNAL__useFinishQuizAttempt({ quizAttemptId }: { quizAttemptId: number }) {
	const { sharedApiClient } = useApiClient();
	const router = useRouter();

	return useMutation({
		mutationFn: () => sharedApiClient.finishQuizAttempt({ id: quizAttemptId }).then((res) => res.data),

		onSuccess: () => {
			router.push(
				$path({
					route: '/frontoffice/quiz-attempt/[quizAttemptId]/results',
					routeParams: { quizAttemptId },
				})
			);
		},
	});
}
