'use client';

import Cookies from 'js-cookie';
import type { Session } from 'next-auth';
import { SessionContext, signOut, useSession } from 'next-auth/react';
import { createContext, useEffect } from 'react';
import {
	AuthApi,
	BackofficeApi,
	Configuration,
	FrontofficeApi,
	SharedApi,
	WebhooksApi,
	WebshopApi,
} from './client';

type ApiClientContextType = {
	authApiClient: AuthApi;
	webshopApiClient: WebshopApi;
	frontofficeApiGen: FrontofficeApi;
	backofficeApiClient: BackofficeApi;
	sharedApiClient: SharedApi;
	webhooksApiClient: WebhooksApi;
	session: Session | null;
};

const ApiClientContext = createContext<Partial<ApiClientContextType>>({
	authApiClient: undefined,
	webshopApiClient: undefined,
	frontofficeApiGen: undefined,
	backofficeApiClient: undefined,
	sharedApiClient: undefined,
	webhooksApiClient: undefined,
	session: null,
});

const ApiClientProvider = ({ children }: any) => {
	const { data: session, status } = useSession();
	const headers = {
		Authorization: session ? `Bearer ${session?.access}` : undefined,
		'X-CSRFToken': Cookies.get('csrftoken') || Cookies.get('next-auth.csrf-token'),
	};

	const configuration = new Configuration({
		basePath: process.env.NEXT_PUBLIC_API_BASE_PATH || 'https://gradivo.hr',
		baseOptions: {
			headers,
		},
	});

	const authApiClient = new AuthApi(configuration);
	const webshopApiClient = new WebshopApi(configuration);
	const frontofficeApiGen = new FrontofficeApi(configuration);
	const backofficeApiClient = new BackofficeApi(configuration);
	const sharedApiClient = new SharedApi(configuration);
	const webhooksApiClient = new WebhooksApi(configuration);

	useEffect(() => {
		if (session?.error === 'REFRESH_TOKEN_ERROR') {
			signOut();
		}
	}, [session]);

	if (status === 'loading') return null;

	return (
		<ApiClientContext.Provider
			value={{
				authApiClient,
				webshopApiClient,
				frontofficeApiGen,
				backofficeApiClient,
				sharedApiClient,
				webhooksApiClient,
				session,
			}}>
			{children}
		</ApiClientContext.Provider>
	);
};

export { ApiClientContext, ApiClientProvider, SessionContext };
