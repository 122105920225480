import useApiClient from '@features/api/useApiClient';
import { useQuery } from '@tanstack/react-query';

function INTERNAL__useGetBankTransactionVendorList() {
	const { queries } = useApiClient();

	return useQuery({
		...queries.bankTransactions.transactions._ctx.vendors._ctx.list({}),
	});
}

export default INTERNAL__useGetBankTransactionVendorList;
