import type {
	BackofficeApiGetBankAccountTransactionListRequest,
	PaginatedBankAccountTransactionList,
} from '@features/api/client';
import useApiClient from '@features/api/useApiClient';
import { useInfiniteQuery } from '@tanstack/react-query';

function INTERNAL__useGetBankTransactionList({
	requestData,
	initialData,
}: {
	requestData: BackofficeApiGetBankAccountTransactionListRequest;
	initialData: {
		pages: Array<PaginatedBankAccountTransactionList>;
		pageParams: Array<number>;
	};
}) {
	const { backofficeApiClient, queries } = useApiClient();

	return useInfiniteQuery({
		queryKey: queries.bankTransactions.transactions._ctx.list(requestData).queryKey,
		queryFn: () => {
			return backofficeApiClient.getBankAccountTransactionList(requestData).then((res) => res.data);
		},
		initialPageParam: 1,
		initialData: initialData,
		getNextPageParam: (lastPage) => {
			if (!lastPage.next || !requestData.offset) return undefined;

			return requestData.offset + 1;
		},
		select: (data) => {
			const lastPage = data.pages.at(-1);

			return {
				results: lastPage?.results,
				pages: data.pages,
				count: lastPage?.count || 0,
				pageParams: data.pages.map((page, index) => index + 1),
			};
		},
	});
}

export default INTERNAL__useGetBankTransactionList;
