import type {
	BackofficeApiUpdateBankAccountTransactionRequest,
	PaginatedBankAccountTransactionList,
} from '@features/api/client';
import useApiClient from '@features/api/useApiClient';
import { getRandomInt } from '@features/shared/utils/getRandomInt';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import type { QueryKeyToReturnType } from 'settings/types';

function INTERNAL__useUpdateBankTransaction({
	accountId,
	tableParams,
}: {
	accountId?: number;
	tableParams?: {
		limit: number;
		offset: number;
	};
}) {
	const { queries, backofficeApiClient } = useApiClient();
	const queryClient = useQueryClient();

	const listQueryKey = queries.bankTransactions.transactions._ctx.list({
		account: accountId,
		...tableParams,
	}).queryKey;

	type VendorsResultType = QueryKeyToReturnType<
		typeof queries.bankTransactions.transactions._ctx.vendors._ctx.list
	>;

	return useMutation({
		mutationFn: (body: BackofficeApiUpdateBankAccountTransactionRequest) => {
			return backofficeApiClient.updateBankAccountTransaction(body).then((res) => res.data);
		},
		onMutate: async (requestData) => {
			queryClient.cancelQueries({
				queryKey: queries.bankTransactions.transactions.queryKey,
			});

			const previousData = queryClient.getQueryData(listQueryKey);

			const vendors = queryClient.getQueryData<VendorsResultType>(
				queries.bankTransactions.transactions._ctx.vendors._ctx.list({}).queryKey
			);

			queryClient.setQueryData(listQueryKey, (old: any) => {
				if (!old) return;

				const pages: PaginatedBankAccountTransactionList[] = old?.pages;

				const newPages = pages.map((page, index) => {
					if (index !== tableParams?.offset) return page;

					const vendor = vendors?.results.find(
						(vendor) =>
							vendor.id === requestData.patchedBankAccountTransactionUpdateRequest?.vendor
					);

					console.log({ vendor, vendors });

					return {
						...page,
						results: page.results.map((transaction) => {
							if (transaction.id !== requestData.id) return transaction;

							const shouldUpdateTags =
								requestData.patchedBankAccountTransactionUpdateRequest?.should_update_tags;
							const tags =
								requestData.patchedBankAccountTransactionUpdateRequest?.tags?.map((tag) => ({
									tag: tag.tag,
									// @ts-ignore
									id: tag.id || getRandomInt(100000),
								})) || [];

							return {
								...transaction,
								...requestData.patchedBankAccountTransactionUpdateRequest,
								tags: shouldUpdateTags ? tags : transaction.tags,
								vendor: requestData.patchedBankAccountTransactionUpdateRequest?.vendor
									? {
											id: getRandomInt(100000),
											vendor_name: vendor?.vendor_name,
										}
									: transaction.vendor,
							};
						}),
					};
				});

				return {
					...old,
					pages: newPages,
				};
			});

			return { previousData };
		},
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: queries.bankTransactions.transactions.queryKey,
			});
		},
		onError: (err, requestData, context) => {
			console.error(err);
			queryClient.setQueryData(listQueryKey, context?.previousData);
		},
	});
}

export default INTERNAL__useUpdateBankTransaction;
