import productFragment from './product';

const cartFragment = /* GraphQL */ `
	fragment cart on Cart {
		id
		checkoutUrl
		cost {
			subtotalAmount {
				amount
				currencyCode
			}
			totalAmount {
				amount
				currencyCode
			}
			totalTaxAmount {
				amount
				currencyCode
			}
		}
		discountAllocations {
			discountedAmount {
				amount
				currencyCode
			}
			... on CartAutomaticDiscountAllocation {
				__typename
				title
			}
			... on CartCodeDiscountAllocation {
				__typename
				code
			}
			... on CartCustomDiscountAllocation {
				__typename
				title
			}
		}
		discountCodes {
			applicable
			code
		}
		lines(first: 100) {
			edges {
				node {
					id
					quantity
					cost {
						amountPerQuantity {
							amount
							currencyCode
						}
						compareAtAmountPerQuantity {
							amount
							currencyCode
						}
						subtotalAmount {
							amount
							currencyCode
						}
						totalAmount {
							amount
							currencyCode
						}
					}
					sellingPlanAllocation {
						sellingPlan {
							id
						}
					}
					discountAllocations {
						discountedAmount {
							amount
							currencyCode
						}
						... on CartAutomaticDiscountAllocation {
							__typename
							discountedAmount {
								amount
								currencyCode
							}
							title
						}
						... on CartCodeDiscountAllocation {
							__typename
							code
							discountedAmount {
								amount
								currencyCode
							}
						}
						... on CartCustomDiscountAllocation {
							__typename
							discountedAmount {
								amount
								currencyCode
							}
							title
						}
					}
					merchandise {
						... on ProductVariant {
							id
							title
							selectedOptions {
								name
								value
							}
							product {
								...product
							}
						}
					}
				}
			}
		}
		totalQuantity
	}
	${productFragment}
`;

export default cartFragment;
