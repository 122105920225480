import type { BackofficeApiCreateProblemsImportCandidateImageRequest } from '@features/api/client';
import useApiClient from '@features/api/useApiClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';

function INTERNAL__useCreateProblemsImportCandidateImage() {
	const queryClient = useQueryClient();
	const { backofficeApiClient, queries } = useApiClient();

	return useMutation({
		mutationFn: (body: BackofficeApiCreateProblemsImportCandidateImageRequest) => {
			return backofficeApiClient.createProblemsImportCandidateImage(body).then((res) => res.data);
		},
		onMutate: async (variables) => {
			await queryClient.cancelQueries({ queryKey: queries.problemImports.candidates.queryKey });
		},
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: queries.problemImports.candidates.queryKey,
			});
		},
	});
}

export default INTERNAL__useCreateProblemsImportCandidateImage;
