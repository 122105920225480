import type { BackofficeApiStartProblemsImportProcessRequest } from '@features/api/client';
import useApiClient from '@features/api/useApiClient';
import { useMutation } from '@tanstack/react-query';
import { $path } from 'next-typesafe-url';
import { useRouter } from 'next/navigation';

function INTERNAL__useStartProblemsImportProccess({ problemSourceId }: { problemSourceId: number }) {
	const router = useRouter();
	const { backofficeApiClient } = useApiClient();
	return useMutation({
		mutationFn: (body: BackofficeApiStartProblemsImportProcessRequest) => {
			return backofficeApiClient.startProblemsImportProcess(body).then((res) => res.data);
		},
		onSuccess: () => {
			router.push(
				$path({
					route: '/backoffice/problem-sources/[id]/problems-import/editor',
					routeParams: {
						id: problemSourceId,
					},
				})
			);
		},
	});
}

export default INTERNAL__useStartProblemsImportProccess;
