import type { QuizAttemptCreateRequestRequest } from '@features/api/client';
import useApiClient from '@features/api/useApiClient';
import { useMutation } from '@tanstack/react-query';

export function INTERNAL__useCreateQuizAttempt() {
	const { sharedApiClient } = useApiClient();

	return useMutation({
		mutationFn: (body: QuizAttemptCreateRequestRequest) => {
			return sharedApiClient.createQuizAttempt({ quizAttemptCreateRequestRequest: body });
		},
	});
}
