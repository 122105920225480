import type { BankAccount } from '@features/api/client';
import useApiClient from '@features/api/useApiClient';
import { useQuery } from '@tanstack/react-query';

function INTERNAL__useGetBankAccountData({ initialData }: { initialData?: BankAccount }) {
	const { queries } = useApiClient();

	return useQuery({
		...queries.bankTransactions.balances._ctx.single(),
		initialData,
	});
}

export default INTERNAL__useGetBankAccountData;
