import type { BackofficeApiUpdateProblemsImportCandidateRequest } from '@features/api/client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from '@features/api/api.client';
import useApiClient from '@features/api/useApiClient';

function INTERNAL__useBulkUpdateProblemsImportCandidate({ problemSourceId }: { problemSourceId: number }) {
	const queryClient = useQueryClient();
	const { queries } = useApiClient();

	const { mutateAsync: updateProblemsImportCandidate } =
		apiClient.problemsImport.useUpdateProblemsImportCandidate({ problemSourceId });

	return useMutation({
		mutationFn: (body: BackofficeApiUpdateProblemsImportCandidateRequest[]) => {
			const promises = body.map((imageRequestData) => {
				return updateProblemsImportCandidate(imageRequestData);
			});

			return Promise.all(promises || []);
		},
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: queries.problemImports.candidates._ctx.list({ problemSource: problemSourceId })
					.queryKey,
			});
		},
	});
}

export default INTERNAL__useBulkUpdateProblemsImportCandidate;
