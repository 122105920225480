import INTERNAL__useCreateBankTransactionVendor from './client/useCreateBankTransactionVendor';
import INTERNAL__useGetBankAccountData from './client/useGetBankAccountData';
import INTERNAL__useGetBankTransactionList from './client/useGetBankTransactionList';
import INTERNAL__useGetBankTransactionTagList from './client/useGetBankTransactionTagList';
import INTERNAL__useGetBankTransactionVendorList from './client/useGetBanktransactionVendorList';
import INTERNAL__useGetUploadedFilesFromGoogleDrive from './client/useGetUploadedFilesFromGoogleDrive';
import INTERNAL__useUpdateBankTransaction from './client/useUpdateBankTransaction';
import INTERNAL__useUploadFilesToGoogleDrive from './client/useUploadFilesToGoogleDrive';

export const _bankTransactionsApiClient = {
	useCreateBankTransactionVendor: INTERNAL__useCreateBankTransactionVendor,
	useGetBankTransactionTagList: INTERNAL__useGetBankTransactionTagList,
	useGetBankTransactionVendorList: INTERNAL__useGetBankTransactionVendorList,
	useUpdateBankTransaction: INTERNAL__useUpdateBankTransaction,
	useGetBankTransactionList: INTERNAL__useGetBankTransactionList,
	useUploadFilesToGoogleDrive: INTERNAL__useUploadFilesToGoogleDrive,
	useGetUploadedFilesFromGoogleDrive: INTERNAL__useGetUploadedFilesFromGoogleDrive,
	useGetBankAccountData: INTERNAL__useGetBankAccountData,
};
