import useApiClient from '@features/api/useApiClient';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'settings/queryKeys';

type useGetDigitalTextbookCoursesProps = {
	id: number;
};

export function INTERNAL__useGetDigitalTextbookCourses({ id }: useGetDigitalTextbookCoursesProps) {
	const { backofficeApiClient } = useApiClient();

	return useQuery({
		queryKey: queryKeys.digitalTextbook.courses(id),
		queryFn: async () => {
			const response = await backofficeApiClient.getCourseList({
				digitalTextbook: id,
			});

			return response.data;
		},
	});
}
