import type {
	BackofficeApiGetProblemsImportImageListRequest,
	PaginatedProblemsImportImageList,
} from '@features/api/client';
import useApiClient from '@features/api/useApiClient';
import { useQuery } from '@tanstack/react-query';

function INTERNAL__useGetProblemsImportImageList({
	body,
	initialData,
}: {
	body: BackofficeApiGetProblemsImportImageListRequest;
	initialData: PaginatedProblemsImportImageList;
}) {
	const { queries } = useApiClient();

	return useQuery({
		...queries.problemImports.images._ctx.list(body),
		initialData,
	});
}

export default INTERNAL__useGetProblemsImportImageList;
