import type { BackofficeApiUpdateProblemsImportCandidateImageRequest } from '@features/api/client';
import useApiClient from '@features/api/useApiClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';

function INTERNAL__useUpdateProblemsImportCandidateImage() {
	const queryClient = useQueryClient();
	const { backofficeApiClient, queries } = useApiClient();

	return useMutation({
		mutationFn: (body: BackofficeApiUpdateProblemsImportCandidateImageRequest) => {
			return backofficeApiClient.updateProblemsImportCandidateImage(body).then((res) => res.data);
		},
		onMutate: async (variables) => {
			await queryClient.cancelQueries({ queryKey: queries.problemImports.candidates.queryKey });
		},
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: queries.problemImports.candidates.queryKey,
			});
		},
	});
}

export default INTERNAL__useUpdateProblemsImportCandidateImage;
