import type { BackofficeApiUploadFilesToGoogleDriveRequest } from '@features/api/client';
import useApiClient from '@features/api/useApiClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';

function INTERNAL__useUploadFilesToGoogleDrive() {
	const { queries, backofficeApiClient } = useApiClient();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (body: BackofficeApiUploadFilesToGoogleDriveRequest) => {
			return backofficeApiClient.uploadFilesToGoogleDrive(body);
		},
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: queries.bankTransactions.transactions.queryKey,
			});
		},
	});
}

export default INTERNAL__useUploadFilesToGoogleDrive;
