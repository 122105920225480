import type { QuizAttempt } from '@features/api/client';
import useApiClient from '@features/api/useApiClient';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'settings/queryKeys';

export function INTERNAL__useGetActiveQuizAttempt({
	quizId,
	initialData,
}: {
	quizId: number;
	initialData?: QuizAttempt;
}) {
	const { sharedApiClient } = useApiClient();

	return useQuery({
		queryKey: queryKeys.quiz.activeAttempt(quizId),
		queryFn: () => {
			return sharedApiClient.getActiveQuizAttempt({ quiz: quizId }).then((res) => res.data);
		},

		initialData: initialData,
		// refetchOnWindowFocus: false,
		// refetchOnMount: false,
		// refetchOnReconnect: false,
	});
}
