import type { PatchedQuizAttemptUpdateRequestRequest } from '@features/api/client';
import useApiClient from '@features/api/useApiClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'settings/queryKeys';

export function INTERNAL__useUpdateQuizAttmept({
	quizId,
	quizAttemptId,
}: {
	quizId: number;
	quizAttemptId: number;
}) {
	const queryClient = useQueryClient();
	const { sharedApiClient } = useApiClient();

	return useMutation({
		mutationFn: (body: PatchedQuizAttemptUpdateRequestRequest) =>
			sharedApiClient
				.updateQuizAttempt({ id: quizAttemptId, patchedQuizAttemptUpdateRequestRequest: body })
				.then((res) => res.data),

		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: queryKeys.quiz.activeAttempt(quizId) });
		},
	});
}
