import useApiClient from '@features/api/useApiClient';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'settings/queryKeys';

export function INTERNAL__useGetDigitalTextbookSectionPrintPreview({
	textbookId,
	sectionId,
}: {
	textbookId: number;
	sectionId: number;
}) {
	const { backofficeApiClient } = useApiClient();

	return useQuery({
		queryKey: queryKeys.digitalTextbook.sectionPrint(textbookId, sectionId),
		queryFn: () => {
			return backofficeApiClient
				.getDigitalTextbookSectionPrintPreview({ id: sectionId })
				.then((res) => res.data);
		},
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
	});
}
