import type { SharedApiGetDigitalTextbookListRequest } from '@features/api/client';
import useApiClient from '@features/api/useApiClient';
import { useMutation } from '@tanstack/react-query';

export function INTERNAL__useGetDigitalTextbookList() {
	const { sharedApiClient } = useApiClient();

	return useMutation({
		mutationFn: async (body: SharedApiGetDigitalTextbookListRequest) => {
			return await sharedApiClient.getDigitalTextbookList(body).then((res) => res.data);
		},
	});
}
