import type { BackofficeApiUpdateProblemsImportImageRequest } from '@features/api/client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from '@features/api/api.client';
import useApiClient from '@features/api/useApiClient';

function INTERNAL__useBulkUpdateProblemsImportImage({ problemSourceId }: { problemSourceId: number }) {
	const queryClient = useQueryClient();
	const { queries } = useApiClient();

	const { mutateAsync: updateProblemsImportImage } = apiClient.problemsImport.useUpdateProblemImportImage({
		problemSourceId,
	});

	return useMutation({
		mutationFn: (body: BackofficeApiUpdateProblemsImportImageRequest[]) => {
			const promises = body.map((imageRequestData) => {
				return updateProblemsImportImage(imageRequestData);
			});

			return Promise.all(promises || []);
		},
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: queries.problemImports.images._ctx.list({ problemSource: problemSourceId })
					.queryKey,
			});
		},
	});
}

export default INTERNAL__useBulkUpdateProblemsImportImage;
