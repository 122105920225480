import type {
	BackofficeApiGetProblemsImportCandidateListRequest,
	PaginatedProblemsImportCandidateList,
} from '@features/api/client';
import useApiClient from '@features/api/useApiClient';
import { useQuery } from '@tanstack/react-query';

function INTERNAL__useGetProblemsImportCandidateList({
	requestData,
	initialData,
}: {
	requestData: BackofficeApiGetProblemsImportCandidateListRequest;
	initialData?: PaginatedProblemsImportCandidateList;
}) {
	const { queries } = useApiClient();

	return useQuery({
		...queries.problemImports.candidates._ctx.list(requestData),
		initialData,
	});
}

export default INTERNAL__useGetProblemsImportCandidateList;
