import useApiClient from '@features/api/useApiClient';
import { useMutation } from '@tanstack/react-query';
import { $path } from 'next-typesafe-url';
import { useRouter } from 'next/navigation';

function INTERNAL__useCreateProblemsImportAttempt({ problemSourceId }: { problemSourceId: number }) {
	const router = useRouter();
	const { backofficeApiClient } = useApiClient();

	return useMutation({
		mutationFn: async () => {
			const response = await backofficeApiClient.createProblemsImportAttempt({
				problemsImportAttemptCreateRequestRequest: { problem_source: problemSourceId },
			});

			return response.data;
		},
		onSuccess: () => {
			router.push(
				$path({
					route: '/backoffice/problem-sources/[id]',
					routeParams: {
						id: problemSourceId,
					},
				})
			);
		},
	});
}

export default INTERNAL__useCreateProblemsImportAttempt;
