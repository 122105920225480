import useApiClient from '@features/api/useApiClient';
import { useQuery } from '@tanstack/react-query';

function INTERNAL__useGetProblemsImportCandidateImageList({ problemSourceId }: { problemSourceId: number }) {
	const { queries } = useApiClient();

	return useQuery({
		...queries.problemImports.candidates._ctx.images._ctx.list({
			problemSource: problemSourceId,
		}),
	});
}

export default INTERNAL__useGetProblemsImportCandidateImageList;
