import type { QuizProblemAttemptedAnswerRequestRequest } from '@features/api/client';
import useApiClient from '@features/api/useApiClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'settings/queryKeys';

export function INTERNAL__useCreateOrUpdateAttemptedAnswer({ quizId }: { quizId: number }) {
	const queryClient = useQueryClient();
	const { sharedApiClient } = useApiClient();

	return useMutation({
		mutationFn: async (body: QuizProblemAttemptedAnswerRequestRequest) => {
			return await sharedApiClient
				.createOrUpdateAttemptedAnswer({ quizProblemAttemptedAnswerRequestRequest: body })
				.then((res) => res.data);
		},
		onMutate: () => {
			queryClient.cancelQueries({ queryKey: queryKeys.quiz.activeAttempt(quizId) });
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: queryKeys.quiz.activeAttempt(quizId) });
		},
	});
}
