import { queryKeys } from 'settings/queryKeys';
import { useQuery } from '@tanstack/react-query';
import { getCart } from '@features/api/shopify';
import type { Cart } from '@features/api/shopify/types';
import Cookies from 'js-cookie';

export function INTERNAL__useGetCart({ initialData }: { initialData?: Cart }) {
	return useQuery({
		queryKey: queryKeys.cart.base,
		queryFn: async () => {
			const cartId = Cookies.get('cartId');

			if (!cartId) throw new Error('Cart not found');

			const cart = await getCart(cartId);
			if (!cart) throw new Error('Cart not found');

			return cart;
		},
		initialData,
		enabled: !!Cookies.get('cartId'),
	});
}
