import INTERNAL__useBulkUpdateProblemsImportCandidate from './client/useBulkUpdateProblemsImportCandidate';
import INTERNAL__useBulkUpdateProblemsImportImage from './client/useBulkUpdateProblemsImportImage';
import INTERNAL__useCreateProblemsImportAttempt from './client/useCreateProblemsImportAttempt';
import INTERNAL__useCreateProblemsImportCandidate from './client/useCreateProblemsImportCandidate';
import INTERNAL__useDeleteProblemsImportCandidate from './client/useDeleteProblemsImportCandidate';
import INTERNAL__useGetProblemsImportCandidateList from './client/useGetProblemsImportCandidateList';
import INTERNAL__useGetProblemsImportImageList from './client/useGetProblemsImportImageList';
import INTERNAL__useUpdateProblemImportImage from './client/useUpdateProblemImportImage';
import INTERNAL__useUpdateProblemsImportCandidate from './client/useUpdateProblemsImportCandidate';
import INTERNAL__useStartProblemsImportProccess from './client/useStartProblemsImportProccess';
import INTERNAL__useGetProblemsImportCandidate from './client/useGetProblemsImportCandidate';
import INTERNAL__useGetProblemsImportCandidateImageList from '@features/backoffice/problems-import/api/client/useGetProblemsImportCandidateImageList';
import INTERNAL__useUpdateProblemsImportCandidateImage from '@features/backoffice/problems-import/api/client/useUpdateProblemsImportCandidateImage';
import INTERNAL__useCreateProblemsImportCandidateImage from '@features/backoffice/problems-import/api/client/useCreateProblemsImportCandidateImage';

export const _problemsImportApiClient = {
	useCreateProblemsImportAttempt: INTERNAL__useCreateProblemsImportAttempt,
	useGetProblemsImportImageList: INTERNAL__useGetProblemsImportImageList,
	useUpdateProblemImportImage: INTERNAL__useUpdateProblemImportImage,
	useBulkUpdateProblemsImportImage: INTERNAL__useBulkUpdateProblemsImportImage,
	useGetProblemsImportCandidateList: INTERNAL__useGetProblemsImportCandidateList,
	useUpdateProblemsImportCandidate: INTERNAL__useUpdateProblemsImportCandidate,
	useBulkUpdateProblemsImportCandidate: INTERNAL__useBulkUpdateProblemsImportCandidate,
	useDeleteProblemsImportCandidate: INTERNAL__useDeleteProblemsImportCandidate,
	useCreateProblemsImportCandidate: INTERNAL__useCreateProblemsImportCandidate,
	useStartProblemsImportProccess: INTERNAL__useStartProblemsImportProccess,
	useGetProblemsImportCandidate: INTERNAL__useGetProblemsImportCandidate,
	useGetProblemsImportCandidateImageList: INTERNAL__useGetProblemsImportCandidateImageList,
	useUpdateProblemsImportCandidateImage: INTERNAL__useUpdateProblemsImportCandidateImage,
	useCreateProblemsImportCandidateImage: INTERNAL__useCreateProblemsImportCandidateImage,
};
