import type { ProblemsImportCandidate } from '@features/api/client';
import useApiClient from '@features/api/useApiClient';
import { useQuery } from '@tanstack/react-query';

function INTERNAL__useGetProblemsImportCandidate({
	problemsImportCandidateId,
	enabled,
	initialData,
}: {
	problemsImportCandidateId?: number;
	initialData?: ProblemsImportCandidate;
	enabled?: boolean;
}) {
	const { queries } = useApiClient();

	if (!problemsImportCandidateId) return null;

	return useQuery({
		...queries.problemImports.candidates._ctx.single({ id: problemsImportCandidateId }),
		initialData,
		enabled,
	});
}

export default INTERNAL__useGetProblemsImportCandidate;
